<template>
  <div>
    <b-sidebar
      type="is-light"
      :fullheight="true"
      :overlay="true"
			mobile="fullwidth"
      v-model="open"
    >
			<div class="px-4 py-4">
				<div class="columns is-mobile">
					<div class="column is-8">
						<b-pagination
							:current.sync="currentPageSidebar"
							:total="section.counter"
							:per-page="section.questionToDisplay"
							aria-next-label="Next page"
							aria-previous-label="Previous page"
							aria-page-label="Page"
							aria-current-label="Current page"
							size="is-small"
							simple
						></b-pagination>
					</div>
					<div class="column is-4 has-text-right">
						<b-button @click="open = false" 
							type="is-hcc" outlined
							icon-pack="fas"
							size="is-small"
							icon-right="compress-alt">
							Close
						</b-button>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column is-12">
						<div class="buttons">
							<template v-for="(question, i) in section.questionList">
								<b-button rounded :key="i"
									size="is-small"
									:type="buttonType(question)"
									@click="scrollToQuestion(question)"
									v-show="
										section.questionToDisplay * (currentPageSidebar - 1) < question.index &&
										section.questionToDisplay * currentPageSidebar >= question.index
									">
									{{question.index}}
								</b-button>
							</template>
						</div>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column is-12">
						<p><b-button type="is-light" size="is-small" rounded>{{totalUnanswered}}</b-button> Unanswered</p>
						<p><b-button type="is-hcc" size="is-small" rounded>{{totalAnswered}}</b-button> Answered</p>
						<p><b-button type="is-warning" size="is-small" rounded>{{totalMarkedQuestion}}</b-button> Marked Question</p>
					</div>
				</div>
				<hr>
				<p class="has-text-grey-light is-size-7">{{appNameVersion}}</p>
			</div>
    </b-sidebar>
    <b-button @click="open = true" outlined
			class="is-hidden-desktop"
			id="btn-info-progress"
			type="is-success"
			style="position:fixed;top:11%;">
			{{questionProgress}}
		</b-button>
    <b-button @click="open = true" outlined
			class="is-hidden-mobile"
			id="btn-info-progress"
			type="is-success"
			icon-pack="fas"
			icon-right="external-link-alt"
			style="position:fixed;top:11%;">
			Terjawab {{questionProgress}}
		</b-button>
  </div>
</template>

<script>
export default {
  props: {
		section: {
      default: () => {},
    },
		currentPage: {
			default: () => 1
		}
	},
  data() {
    return {
			appNameVersion: null,
      open: false,
			currentPageSidebar: 1,
    }
  },
	watch:{
		currentPage(val){
			this.currentPageSidebar = val
		},
		currentPageSidebar(val){
			this.$emit('change-page', val)
		}
	},
  computed:{
    questionsLength(){
		}
	},
	computed:{
		questionProgress(){
			let total = this.section.counter
			let totalAnswered = this.section.questionList.filter(question => question.hasAnswer == true).length
			return totalAnswered +' / ' + total
		},
		totalUnanswered(){
			return this.section.questionList.filter(question => question.hasAnswer == false).length
		},
		totalAnswered(){
			return this.section.questionList.filter(question => question.hasAnswer == true).length
		},
		totalMarkedQuestion(){
			return this.section.questionList.filter(question => question.checkLater == true).length
		},
	},
	methods:{
		openSidebar(){
			this.open = true
		},
		closeSidebar(){
			this.open = false
		},
		scrollToQuestion(question){
			this.open = false
			setTimeout(()=>{
				this.$emit('uncheck-later', question)
			}, 1500)

			document.getElementById('question-'+question.id)
				.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
		},
		buttonType(question){
			if(question.checkLater) return 'is-warning'
			else if(question.hasAnswer) return 'is-hcc'
			else return 'is-light'
		}
	},
	mounted(){
    this.appNameVersion = process.env.VUE_APP_NAME+' (versi '+process.env.VUE_APP_VERSION+')'
	}
}
</script>

<style>
#btn-info-progress {
  background-color: #fff;
	position: fixed;
	top: 11%;
	z-index: 1;
}
#btn-info-progress:hover{
  background-color: #48c78e;
}
#btn-info-progress.is-hidden-desktop {
    height: 24px;
    padding: 5px;
    font-size: 14px;
		left: 2px;
}
@media screen and (min-width: 1024px){
	#btn-info-progress {
			left: 15px;
	}
}
@media screen and (min-width: 1400px){
	#btn-info-progress {
			left: 80px;
	}
}
@media screen and (min-width: 1600px){
  #btn-info-progress {
    left: 5px;
  }
}
@media screen and (min-width: 1800px){
  #btn-info-progress {
    left: 90px;
  }
}
@media screen and (min-width: 2000px){
  #btn-info-progress {
    left: 200px;
  }
}
@media screen and (min-width: 2250px){
  #btn-info-progress {
    left: 340px;
  }
}
@media screen and (min-width: 2500px){
  #btn-info-progress {
    left: 440px;
  }
}
</style>